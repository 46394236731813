<template>
  <section id="login" class="login">
    <v-container>
      <v-row justify="center" class="main-row">
        <v-col align-self="center" md="4">
          <v-card elevation="2">
            <v-row justify="center" class="pt-8">
              <v-img
                contain
                max-height="500"
                max-width="500"
                src="@/assets/logo.png"
                class="ma-auto"
              ></v-img
            ></v-row>

            <v-card-title class="text-center d-inline px-8">
              <h2>Resetowanie hasła</h2>
            </v-card-title>
            <v-card-text class="px-8">
              <v-form class="pb-3" ref="form">
                <v-text-field
                  v-model="password1"
                  ref="password1"
                  label="Hasło"
                  :loading="loading"
                  :rules="[rules.required, rules.min, rules.counter]"
                  :type="showpassword1 ? 'text' : 'password'"
                  :append-icon="showpassword1 ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showpassword1 = !showpassword1"
                  @keydown.enter="validateResetPassword"
                  counter
                ></v-text-field>

                <v-text-field
                  v-model="password2"
                  ref="password2"
                  label="Powtórz hasło"
                  :loading="loading"
                  counter
                  :rules="[rules.required, rules.min, rules.counter]"
                  :type="showpassword2 ? 'text' : 'password'"
                  :append-icon="showpassword2 ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showpassword2 = !showpassword2"
                  @keydown.enter="validateResetPassword"
                ></v-text-field>

                <v-btn
                  class="mt-2"
                  :loading="loading"
                  color="success"
                  @click="validateResetPassword"
                  block
                  large
                >
                  Resetuj hasło
                </v-btn>

                <v-btn
                  class="mt-4"
                  :loading="loading"
                  :disabled="loading"
                  text
                  color="primary"
                  @click="toLoginPage"
                  block
                  x-small
                >
                  Powrót do logowania
                </v-btn>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar v-model="viewInfo" :timeout="2000" :color="infoType">
      {{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="viewInfo = false">
          Zamknij
        </v-btn>
      </template>
    </v-snackbar>
  </section>
</template>

<script>
export default {
  name: "NewPassword",
  data() {
    return {
      loading: false,
      viewInfo: false,
      rules: {
        required: (value) => !!value || "Wymagane.",
        counter: (value) => value.length <= 20 || "Max 20 znaków",
        min: (v) => v.length >= 6 || "Min 6 znaków",
      },
      showpassword1: false,
      showpassword2: false,
      password1: "",
      password2: "",
      message: null,
      infoType: "success",
    };
  },
  props: {
    apiLink: {
      type: String,
      required: true,
    },
  },
  computed: {
    form() {
      return {
        password1: this.password1,
        password2: this.password2,
      };
    },
  },
  created() {
    if (!this.$route.params.resetLink) {
      this.$router.push({ name: "Login" });
    }
  },
  methods: {
    validateResetPassword() {
      this.formHasErrors = false;

      Object.keys(this.form).forEach((f) => {
        if (!this.form[f]) this.formHasErrors = true;

        this.$refs[f].validate(true);
      });

      if (!this.formHasErrors) {
        this.validatePasswords();
      }
    },
    validatePasswords() {
      if (this.password1 != this.password2) {
        this.message = "Hasła nie są takie same";
        this.infoType = "error";
        this.viewInfo = true;
      } else {
        this.resetPassword();
      }
    },
    async resetPassword() {
      this.loading = true;
      await this.axios({
        method: "post",
        url: `${this.apiLink}/api/views/clients/resetPass.php`,
        data: {
          password: this.password1,
          link: this.$route.params.resetLink,
        },
      }).then((res) => {
        if (res.status === 200) {
          if (res.data == 1) {
            this.message =
              "Hasło zostało zmienione, zostaniesz przekierowany na stronę logowania";
            this.infoType = "success";
            this.viewInfo = true;
            setTimeout(() => {
              this.toLoginPage();
            }, 2000);
          } else {
            this.message = res.data;
            this.infoType = "error";
            this.viewInfo = true;
          }
        }
      });
      this.loading = false;
    },
    toLoginPage() {
      this.$router.push({
        name: "Login",
      });
    },
  },
};
</script>

<style>
.login .main-row {
  height: 100vh;
}
</style>